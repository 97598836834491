<template>
  <div class="main-container-outer">
    <div class="local-loader-container" v-if="loading">
      <div class="inava-loader"></div>
    </div>
    <div class="main-container-inner">
      <div class="left-text">
        <div class="entity-info">
          <p class="info-title">
            {{ entityDetails?.[0]?.entityName ?? 'N/A' }}
          </p>
        </div>
      </div>
    </div>
    <div class="main-container-inner2">
      <div class="left-text">
      </div>
      <div class="right-text">
        <div class="exception-container">
          <button class="action-button red-button" @click="resetSelectedEntity">
            <span class="button-icon">
              <SvgIcon
                :icon="arrowRotateCcwIcon"
                :size="'medium'"
                class="icon" />
            </span>
            <span>Clear</span>
          </button>
          <button
            class="action-button blue-button"
            @click="submitDuplicateProcessed">
            <span class="button-icon">
              <SvgIcon :icon="checkOutlineIcon" :size="'medium'" class="icon" />
            </span>
            <span>Submit</span>
          </button>
          <div class="placeholder-text">Exception Type:</div>
          <button class="duplicate-button">Duplicate</button>
        </div>
      </div>
    </div>
    <div class="main-container-inner3">
      <div class="left-text">
        <p class="sub-text">Listed below entries are system generated</p>
      </div>
      <div class="right-text">
        <div class="exception-container">
          <button class="action-button red-button" @click="markAsMaster">
            <span class="button-icon">
              <SvgIcon
                :icon="kpiStatusDenyIcon"
                :size="'medium'"
                class="icon" />
            </span>
            <span>Mark as Master</span>
          </button>
          <button class="action-button blue-button" @click="markAsDuplicate">
            <span class="button-icon">
              <SvgIcon
                :icon="windowRestoreIcon"
                :size="'medium'"
                class="icon" />
            </span>
            <span>Mark as Duplicate</span>
          </button>
        </div>
      </div>
    </div>
    <ExceptionHandlingDuplicatesTableGrid
      @selectionChange="handleSelectionChange"
      :data-items="dataItems" />
    <ConfirmationDialog
      :type="dialog.type"
      :visible="dialog.visible"
      :title="dialog.title"
      :bodyText="dialog.text"
      :cancelButtonText="dialog.cancelButtonText"
      :confirmButtonText="dialog.confirmButtonText"
      :closeDialog="closeDialog"
      :triggerFunction="dialog.triggerFunction" />
  </div>
</template>

<script>
import EntityDetails from '@/views/DataManagement/EntityDetails.vue'
import Audit from '@/views/DataManagement/Audit/Audit.vue'
import ConfirmationDialog from '@/components/DMP/ConfirmationDialog.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import ExceptionHandlingDuplicatesTableGrid from './DuplicateGrid/DuplicatesGrid.vue'
import moment from 'moment'
import {
  windowRestoreIcon,
  kpiStatusDenyIcon,
  checkOutlineIcon,
  arrowRotateCcwIcon
} from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-vue-common'

export default {
  name: 'ExceptionHandlingDuplicates',
  components: {
    SvgIcon,
    EntityDetails,
    Audit,
    ConfirmationDialog,
    ExceptionHandlingDuplicatesTableGrid
  },
  data () {
    return {
      windowRestoreIcon,
      kpiStatusDenyIcon,
      checkOutlineIcon,
      arrowRotateCcwIcon,
      dataItems: [],
      isEnabled: false,
      sgaId: '',
      selected: 0,
      isReviewSubmit: false,
      loading: false,
      selectedDataItems: [],
      // confirmation popup
      dialog: {
        type: 'confirm',
        visible: false,
        title: '',
        text: '',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Ok',
        triggerFunction: () => {}
      }
    }
  },
  computed: {
    ...mapState('assignedEntities', ['entityDetails']),
    ...mapState('exceptionHandling', ['exceptionHandlingDuplicates']),
    ...mapGetters('dmp', ['getEntityDetails'])
  },
  watch: {
    exceptionHandlingDuplicates: {
      handler () {
        this.dataItems = this.exceptionHandlingDuplicates
      },
      immediate: true
    }
  },
  async created () {
    this.loading = true
    const { sgaId } = this.getEntityDetails
    this.sgaId = sgaId
    if (!this.sgaId) {
      // If sgaID is not available then redirect to landing page
      this.$router.push({
        name: 'exception-handling'
      })
    }
    await this.getEntityDetailsById({ sgaId: this.sgaId })
    this.loading = false
  },
  methods: {
    ...mapActions('assignedEntities', ['getEntityDetailsById']),
    ...mapActions('review', ['updateEntityStatus']),
    ...mapActions('exceptionHandling', [
      'getExceptionHandlingDuplicates',
      'updateMasterEntity',
      'updateDuplicateEntity',
      'processAllDuplicates',
      'resetProcessedEntity'
    ]),

    backToForm (isEdit) {
      this.isReviewSubmit = isEdit
    },

    handleSelectionChange (selectedDataItems) {
      this.selectedDataItems = selectedDataItems
    },

    getLastUpdatedDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },

    closeDialog () {
      this.dialog.visible = false
    },

    fetchDuplicateGridData () {
      this.selectedDataItems = []
      return this.getExceptionHandlingDuplicates(this.sgaId)
    },

    async markAsMaster () {
      const selectedCount = this.selectedDataItems.length
      if (selectedCount === 0) {
        this.showWarningDialog()
        this.dialog.text = 'Please select at least 1 entity to mark as master'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else if (selectedCount > 1) {
        this.showWarningDialog()
        this.dialog.text = 'Please select only 1 entity to mark as master'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        // Mark Entity As Master
        const selectedEntity = this.selectedDataItems.find(
          (entity) => entity.selected === true
        )

        const entitySummary = `<b>Are you sure you want to mark below entity as master?
        </b><br/><br/>
        <div style="font-size: 13px; text-align: left;"><li>${selectedEntity.sgaId} - ${selectedEntity.legalEntityName}</li></div>`

        try {
          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            const updateMasterResult = await this.updateMasterEntity({
              masterSGAID: selectedEntity.sgaId,
              duplicateBaseSGAID: this.sgaId
            })

            if (updateMasterResult) {
              await this.fetchDuplicateGridData()
            }
            this.dialog.visible = false
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
        } finally {
          this.loading = false
        }
      }
    },

    async markAsDuplicate () {
      if (this.selectedDataItems.length === 0) {
        this.showWarningDialog()
        this.dialog.text =
          'Please select at least 1 entity to mark as duplicate '
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        // Mark Entities As Duplicate
        const selectedEntities = this.selectedDataItems.filter(
          (entity) => entity.selected === true
        )
        const duplicateEntityList = selectedEntities.map((entity) => {
          return `<li>${entity.sgaId} - ${entity.legalEntityName}</li>`
        })

        const entitySummary = `<b>Are you sure, you want to mark below entities as duplicate?</b><br/><br/>
        <div style="font-size: 13px; text-align: left;">${duplicateEntityList.join(
          ''
        )}<div>`

        try {
          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            const updateDuplicateResult = await this.updateDuplicateEntity({
              duplicateSGAIDs: selectedEntities.map((entity) => entity.sgaId),
              duplicateBaseSGAID: this.sgaId
            })

            if (updateDuplicateResult) {
              await this.fetchDuplicateGridData()
            }
            this.dialog.visible = false
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
        } finally {
          this.loading = false
        }
      }
    },

    async submitDuplicateProcessed () {
      try {
        // Current Entity Summary
        let entitySummary = `<div style="text-align: left; line-height: 1.6;">
        <b>Are you sure, you want to proceed with below details?</b><br/><br/>
        <ul>
          <li><b>Current Entity</b></li>
            <ul style="list-style-type:disc; font-size: 13px;"> 
              <li> ${this.sgaId} - ${this.entityDetails?.[0]?.entityName}</li>
            </ul>
        `
        // Master Entity Summary
        const masterEntity = this.dataItems.find((entity) => entity.isMaster)
        const masterSGAID = masterEntity ? masterEntity.sgaId : this.sgaId
        const masterLEN = masterEntity
          ? masterEntity.legalEntityName
          : this.entityDetails?.[0]?.entityName
        entitySummary += `<br/>
        <li><b>Master Entity</b></li>
        <ul style="list-style-type:disc; font-size: 13px;">
          <li> ${masterSGAID} - ${masterLEN}</li>
        </ul>
        `

        // Duplicate Entities Summary
        const duplicateEntity = this.dataItems.filter(
          (entity) => !entity.isMaster
        )
        const duplicateEntityList = duplicateEntity.map((entity) => {
          return `<li> ${entity.sgaId} - ${entity.legalEntityName}</li>`
        })
        entitySummary += `<br/>
          <li><b>Duplicate Entity</b></li>        
            <ul style="list-style-type:disc; font-size: 13px;">          
              ${duplicateEntityList.join('')}
            </ul>
          </ul>        
        </div>
        `

        this.showConfirmDialog()
        this.dialog.text = entitySummary
        this.dialog.triggerFunction = async () => {
          this.loading = true
          const updateDuplicateResult = await this.processAllDuplicates(
            this.sgaId
          )

          if (updateDuplicateResult) {
            this.$router.push({
              name: 'exception-handling'
            })
          }
          this.dialog.visible = false
        }
      } catch (error) {
        // Handle Error Part
      } finally {
        this.loading = false
      }
    },

    async resetSelectedEntity () {
      if (this.selectedDataItems.length === 0) {
        this.showWarningDialog()
        this.dialog.text = 'Please select at least 1 entity to reset'
        this.dialog.triggerFunction = () => {
          this.dialog.visible = false
        }
      } else {
        // Mark Entities As Duplicate
        const selectedEntities = this.selectedDataItems.filter(
          (entity) => entity.selected === true
        )
        const selectedEntityList = selectedEntities.map((entity) => {
          return `<li>${entity.sgaId} - ${entity.legalEntityName}</li>`
        })

        const entitySummary = `<b>Are you sure, you want to reset below entities?</b><br/><br/>
        <div style="font-size: 13px; text-align: left;">${selectedEntityList.join(
          ''
        )}<div>`

        try {
          this.showConfirmDialog()
          this.dialog.text = entitySummary
          this.dialog.triggerFunction = async () => {
            this.loading = true
            const resetEntityResult = await this.resetProcessedEntity({
              selectedSGAIDs: selectedEntities.map((entity) => entity.sgaId),
              duplicateBaseSGAID: this.sgaId
            })

            if (resetEntityResult) {
              await this.fetchDuplicateGridData()
            }
            this.dialog.visible = false
            this.loading = false
          }
        } catch (error) {
          // Handle Error Part
        } finally {
          this.loading = false
        }
      }
    },

    showWarningDialog () {
      this.dialog.type = 'warning'
      this.dialog.visible = true
      this.dialog.title = 'Warning'
      this.dialog.cancelButtonText = 'Cancel'
      this.dialog.confirmButtonText = 'Ok'
    },

    showConfirmDialog () {
      this.dialog.type = 'confirm'
      this.dialog.visible = true
      this.dialog.title = 'Confirm'
      this.dialog.cancelButtonText = 'No'
      this.dialog.confirmButtonText = 'Yes'
    }
  }
}
</script>
<style lang="scss" scoped>
.main-container-outer {
  margin: 0 auto;
  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    background: rgba(0, 0, 0, 0.1);
    z-index: 9;
    .inava-loader {
      position: relative;
      display: flex;
      margin: auto;
      border-radius: 50%;
      border: none;
      z-index: 900;
      width: 70px;
      height: 70px;
      background-image: '@/assets/Inava-logo-GIF.gif';
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
.main-container-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

/* Style for the left text */
.left-text {
  text-align: left;
}

/* Style for the right text */
.right-text {
  text-align: right;
}

.main-container-inner2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 10px 2px 10px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.left-text {
  text-align: left;
}

/* Style for the right text */
.right-text {
  text-align: right;
}

.main-container-inner3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 1px 10px 2px 10px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.entity-info {
  flex: 1 1 auto;

  .info-title {
    margin: 0;
    font-size: 30px;
    letter-spacing: 0px;
    color: var(--inava-primary-dark);
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
  }

  .date-updated {
    display: inline-block;
    color: #1e1e1e;
    font-size: 18px;
    letter-spacing: 0.18px;
    padding-bottom: 5px;
  }
}

.review-pane {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: 22px;

  .status-info {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #888888;

    .status-box {
      background-color: #c4c8f8;
      padding: 9px 15px;
      border-radius: 5px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      cursor: default;

      span {
        padding-left: 6px;
        font-size: 18px;
        color: var(--inava-primary-dark);
      }
    }
  }

  .review-btn {
    padding: 9px 15px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
    width: 220px;
  }

  .review-action-btn {
    display: inline-block;
    padding: 9px 15px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    font-size: 18px;
    width: 98px;
    cursor: pointer;
  }

  .btn-edit {
    border: 1px solid #888888;
    background-color: #888888;
  }

  .btn-confirm {
    border: 1px solid var(--inava-primary-dark);
    background-color: var(--inava-primary-dark);
  }
}

.button-container {
  display: inline-block;
  padding: 10px;
  background-color: #dfdfdf;
  font-size: 16px;
  border-radius: 4px;
  opacity: 1;
}
.button-text {
  margin: 0;
  font-size: 16px;
  color: #000000;
}

.exception-container {
  display: flex;
  align-items: center;
}

/* Style for the duplicate button */
.duplicate-button {
  padding: 8px;
  background-color: #ffffff;
  color: var(--inava-primary-dark);
  border: 1px solid var(--inava-primary-dark);
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
}

.placeholder-text {
  font-size: 18px;
  color: #1e1e1e;
  margin-right: 10px;
}

.sub-text {
  font-size: 16px;
  color: #7d7d7d;
}

.action-button {
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 16px;
}

.button-icon {
  margin-right: 5px;
}

.blue-button {
  background: var(--inava-primary-dark) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inava-primary-dark);
  border-radius: 4px;
}

.red-button {
  background: #f57777 0% 0% no-repeat padding-box;
  border: 1px solid #f57777;
  border-radius: 4px;
}

.submit-button {
  margin-right: 15px;
  color: #000000;
  padding: 8px;
  border: 1px solid;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  opacity: 1;
}
</style>
