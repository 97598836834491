export const exceptionHandlingDuplicatescolumns = [
  {
    field: 'selected',
    width: '45px',
    columnMenu: false,
    headerSelectionValue: false
  },
  {
    field: 'sgaId',
    title: 'SGA ID',
    width: '150px',
    columnMenu: false
  },
  {
    field: 'requestId',
    title: 'Request ID',
    width: '140px',
    columnMenu: false
  },
  {
    field: 'legalEntityName',
    title: 'Legal Entity Name',
    width: '350px',
    resizable: true,
    columnMenu: false,
    className: 'text-center',
    cell: 'LENTemplate'
  },
  {
    field: 'score',
    title: 'Score',
    width: '90px',
    columnMenu: false,
    cell: 'scoreTemplate'
  },
  {
    field: 'countryOfDomicile',
    title: 'Country Of Domicile',
    width: '100px',
    resizable: true,
    columnMenu: false,
    className: 'text-center'
  },
  {
    field: 'countryOfRegistry',
    title: 'Country Of Registry',
    width: '100px',
    resizable: true,
    columnMenu: false
  },
  {
    field: 'ultimateParentRequestId',
    title: 'Ultimate Parent Request Id',
    width: '200px',
    resizable: true,
    columnMenu: false
  },
  {
    field: 'lei',
    title: 'LEI',
    width: '150px',
    sortable: true,
    columnMenu: false
  },
  {
    field: 'lastUpdated',
    title: 'Last Updated',
    width: '160px',
    sortable: true,
    columnMenu: false
  },
  {
    field: 'Action',
    title: 'Action',
    width: '150px',
    cell: 'viewEntityTemplate',
    sortable: false,
    columnMenu: false
  }
]
